<template>
	<v-list-tile v-if="canSeeThemes">
		<v-list-tile-content>
			<SelectWithChips
				v-model="nodeThemes"
				:disabled="(!conditionalDisplay.isMobileApp && !canChangeThemes) || conditionalDisplay.isMobileApp"
				:item-clearable="false"
				:items="vendorThemesWithShortTitle"
				item-short-text="short_title"
				with-short-text-tooltip
				item-text="title"
				item-value="id"
				:label="$t('themes.documents.themes')"
				:loading="fetchingData"
				list-with-chips
				multiple
				return-object
				style="width: 100%"
				@input="updateThemes"
			>
			</SelectWithChips>
		</v-list-tile-content>
	</v-list-tile>
</template>
<script>
import { mapState } from 'vuex'
import DocumentsThemesModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsThemesModuleGuard'

const MAX_THEME_LENGTH = 20;

export default {
	name: 'NodeThemeSelector',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		}),
	},
	mixins: [DocumentsThemesModuleGuard],
	props: {
		value: {
			type: Array,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			fetchingVendorThemeData: false,
			fetchingNodeThemeData: true,
			nodeThemes: [],
			originalNodeThemes: []
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			vendorThemes: state => state.documents.themes.themes
		}),
		vendorThemesWithShortTitle: function () {
			return this.vendorThemes.map(theme => {
				const longer = theme.title.length > MAX_THEME_LENGTH
				theme.short_title = longer ? theme.title.substring(0, MAX_THEME_LENGTH) + '...' : theme.title
				return theme
			})
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		modelThemes: {
			get: function () {
				const modelThemesId = this.model?.map(item => item.themes && item.themes.map(theme => theme.id)).flat()
				return this.vendorThemes.filter(vendorTheme => modelThemesId.includes(vendorTheme.id))
			},
			set: function (newValue) {
				if (this.model) {
					this.model.forEach(element => (element.themes = newValue))
				}
			}
		},
		canSeeThemes: function () {
			if (!this.model || this.model.length === 0) {
				return false;
			}
			const validItems = this.model.filter(item => item !== null && item !== undefined);
			const hasRootOrTrash = validItems.some(item => item.is_root || item.is_trash);
			const allOrNoneAreDocuments = validItems.every(item => item.is_document) || validItems.every(item => !item.is_document);

			return !hasRootOrTrash && allOrNoneAreDocuments;
		},
		canChangeThemes: function () {
			return (
				this.model &&
				!this.isGuest &&
				!this.model?.map(aItem => aItem.is_deleted).includes(true) &&
				!this.model?.map(aItem => aItem.is_root).includes(true) &&
				!this.model?.map(aItem => aItem.is_readonly).includes(true) &&
				(this.themesPermissions.some(permission => permission.write) || this.themesPermissions.length == 0)
			)
		},
		themesPermissions: function () {
			return this.vendorThemes.filter(theme => this.nodeThemes?.some(nodeTheme => nodeTheme.id == theme.id)).map(theme => theme.permission)
		},
		fetchingData: function () {
			return this.fetchingVendorThemeData || this.fetchingNodeThemeData
		}
	},
	watch: {
		vendorId: {
			handler: function () {
				this.loadVendorThemes()
			}
		},
		model: {
			handler: function () {
				this.nodeThemes = []
				this.loadModelThemes()
			}
		}
	},
	mounted: function () {
		if (!this.themes) {
			this.loadVendorThemes()
		}
		this.loadModelThemes()
	},
	methods: {
		updateThemes: function () {
			const updateThemesPromises = []
			this.model.forEach(aItem => {
				updateThemesPromises.push(
					...this.nodeThemes
						.filter(nodeTheme => !this.modelThemes.some(modelTheme => modelTheme.id == nodeTheme.id))
						.map(nodeTheme => this.service.createNodeTheme(this.vendorId, aItem, nodeTheme))
				)
				updateThemesPromises.push(
					...this.modelThemes
						.filter(modelTheme => !this.nodeThemes?.some(nodeTheme => nodeTheme.id == modelTheme.id))
						.map(modelTheme => this.service.deleteNodeTheme(this.vendorId, aItem, modelTheme))
				)
			})
			this.originalNodeThemes = [...this.modelThemes]
			this.modelThemes = [...this.nodeThemes]

			let result = Promise.resolve()
			if (updateThemesPromises.length > 0) {
				result = Promise.all(updateThemesPromises)
					.then(() => {
						this.appEventBus.emit(
							this.appEvents.SNACKBAR_SUCCESS,
							this.model[0].is_folder ? this.$tc('documents.folder_updated', this.model.length) : this.$tc('documents.document_updated', this.model.length)
						)
						return this.loadModelThemes()
					})
					.catch(() => {
						this.cancelUpdate()
					})
					.then(() => {
						if (!(this.themesPermissions.some(permission => permission.write) || this.themesPermissions.length == 0)) {
							this.model.forEach(element => {
								element.is_readonly = true
							})
						}
						this.originalNodeThemes = [...this.modelThemes]
					})
			}
			return result
		},
		loadVendorThemes: function () {
			this.fetchingVendorThemeData = true
			this.service.loadVendorThemes(this.vendorId, { is_usable: true }).finally(() => {
				this.fetchingVendorThemeData = false
			})
		},
		loadModelThemes: function () {
			let result = Promise.resolve()
			if (this.model) {
				const loadModelThemesPromises = []
				const doCancelPreviousRequest = this.model.length > 1 ? false : true
				const doCancelAllOtherRequests = doCancelPreviousRequest
				this.model.forEach(aItem => {
					loadModelThemesPromises.push(this.service.getNodeThemes(this.vendorId, aItem, null, doCancelPreviousRequest, doCancelAllOtherRequests))
				})
				this.fetchingNodeThemeData = true
				result = Promise.all(loadModelThemesPromises).then(() => {
					this.fetchingNodeThemeData = false
				})
			}
			result.finally(() => {
				this.nodeThemes = [...this.modelThemes]
			})
		},
		cancelUpdate: function () {
			this.nodeThemes = [...this.originalNodeThemes]
		}
	}
}
</script>
