// ============================================================================
// DocumentsThemesApi
// ------------------
// Vendor and Node theme api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
	vendor: {
		node: {
			theme: {
				create: function (vendorId, nodeId, themeId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, nodeId: nodeId, themeId: themeId })
					const parameters = params ? { ...params } : {}
					return Backend.POST(
						URLS.api.vendors.nodes.themes.uri,
						[vendorId, nodeId, themeId],
						parameters,
						data,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: function (vendorId, nodeId, themeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					return Backend.DELETE(URLS.api.vendors.nodes.themes.uri, [vendorId, nodeId, themeId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				}
			},
			themes: {
				create: NotImplementedYet,
				read: function (vendorId, nodeId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					return Backend.GET(URLS.api.vendors.nodes.themes.uri, [vendorId, nodeId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		},
		themes: {
			create: NotImplementedYet,
			read: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId })
				return Backend.GET(URLS.api.vendors.themes.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	}
}
