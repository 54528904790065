// ============================================================================
// DocumentsManagerService
// -----------------------
// DocumentsManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/DocumentsThemesApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			node: {
				theme: {
					create: function (vendorId, nodeId, themeId, data = {}) {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId, themeId: themeId })
						return API.vendor.node.theme.create(vendorId, nodeId, themeId, null, data)
					},
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: function (vendorId, nodeId, themeId) {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId, themeId: themeId })
						return API.vendor.node.theme.delete(vendorId, nodeId, themeId)
					}
				},
				themes: {
					create: NotImplementedYet,
					read: function (vendorId, nodeId, params = null, doCancelPreviousRequest = true, doCancelAllOtherRequests = true) {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId })
						return API.vendor.node.themes.read(vendorId, nodeId, params, doCancelPreviousRequest, doCancelAllOtherRequests)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			themes: {
				create: NotImplementedYet,
				read: function (vendorId, params = null) {
					RequiredArguments({ vendorId: vendorId })
					return API.vendor.themes.read(vendorId, params)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	service: {
		vendor: {
			node: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet,
				theme: {
					create: function (vendorId, node, theme) {
						RequiredArguments({ vendorId: vendorId, node: node, theme: theme })
						return Private.requests.vendor.node.theme.create(vendorId, node.id, theme.id, {
							cascade: true
						})
					},
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: function (vendorId, node, theme) {
						RequiredArguments({ vendorId: vendorId, node: node, theme: theme })
						return Private.requests.vendor.node.theme.delete(vendorId, node.id, theme.id)
					}
				},
				themes: {
					create: NotImplementedYet,
					read: function (vendorId, node, params = null, doCancelPreviousRequest = true, doCancelAllOtherRequests = true) {
						RequiredArguments({ vendorId: vendorId, node: node })
						return Private.requests.vendor.node.themes.read(vendorId, node.id, params, doCancelPreviousRequest, doCancelAllOtherRequests).then(themes => {
							node.themes = themes
							return themes
						})
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			themes: {
				load: function (vendorId, params = null) {
					RequiredArguments({ vendorId: vendorId })
					return Private.requests.vendor.themes.read(vendorId, params)
						.then(themes => Private.store.vendor.themes.set(themes))
				}
			}
		}
	},
	store: {
		vendor: {
			themes: {
				set: function (themes) {
					RequiredArguments({ themes: themes })
					return store.dispatch('documents/themes/setThemes', themes)
				}
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	loadVendorThemes: Private.service.vendor.themes.load,

	createNodeTheme: Private.service.vendor.node.theme.create,
	getNodeTheme: Private.service.vendor.node.theme.read,
	updateNodeTheme: Private.service.vendor.node.theme.update,
	deleteNodeTheme: Private.service.vendor.node.theme.delete,

	createNodeThemes: Private.service.vendor.node.themes.create,
	getNodeThemes: Private.service.vendor.node.themes.read,
	updateNodeThemes: Private.service.vendor.node.themes.update,
	deleteNodeThemes: Private.service.vendor.node.themes.delete
}
